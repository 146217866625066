import { Button, Flex } from '@chakra-ui/react';
import { ParagraphHotelListFragmentFragment } from '../../../generated/types';
import Container from '../../Layout/Container';
import TeaserGrid from '../../Common/TeaserGrid';
import Wrapper from '../Wrapper';
import React from 'react';
import Link from 'next/link';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import getAppComponent from '../../../utils/getAppComponent';
import { useStore } from '../../../store/provider';
import addFilterToSearchPageUrl from '../../../utils/addFilterToSearchPageUrl';

interface HotelListProps {
  data: ParagraphHotelListFragmentFragment;
}

const HotelList: React.FC<HotelListProps> = ({ data }) => {
  const store = useStore();
  const TeaserComponent = getAppComponent(
    process.env.APP_ID!,
    store.config.usesNewLayout,
    'HotelTeaser'
  );

  return (
    <Wrapper className="ParagraphHotelList">
      <Container>
        <TeaserGrid
          columns={store.config.usesNewLayout ? 1 : 3}
          spacing={store.config.usesNewLayout ? 16 : 8}
        >
          {data.data!.items!.map(
            (teaser) =>
              teaser && <TeaserComponent key={teaser!.id} data={teaser!} />
          )}
        </TeaserGrid>

        <Flex flex={1} justifyContent="center" mt={8}>
          <Link
            href={addFilterToSearchPageUrl(
              data.category.searchPageUrl ||
                `/entdecken/${data.category.frontendUrl}`,
              data.countries,
              data.regions
            )}
            passHref
          >
            <Button variant="outline" as="a" colorScheme="brand">
              {data.linktext || 'Mehr entdecken'}{' '}
              <ArrowForwardIcon marginLeft={2} fontSize="sm" />
            </Button>
          </Link>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default HotelList;
